/* eslint-disable react/no-array-index-key */

import React, { useMemo, useState, useEffect, useReducer } from 'react'
import produce from 'immer'

import get_ from 'lodash/get'
import pick_ from 'lodash/pick'

import flow_ from 'lodash/fp/flow'
import pickFp_ from 'lodash/fp/pick'
import omitFp_ from 'lodash/fp/omit'
import valuesFp_ from 'lodash/fp/values'
import mapFp_ from 'lodash/fp/map'
import sumFp_ from 'lodash/fp/sum'
import filterFp_ from 'lodash/fp/filter'
import findFp_ from 'lodash/fp/find'
import { useTranslation } from 'react-i18next'
import fetchStatusesReducer from '../../../redux/reducers/fetchStatuses/forms'
import {
  getAggregateRowFetchStatuses,
} from '../../../redux/selectors/rewrite/fetchStatuses/forms/formWithFieldArrays'
import {
  getItemSkusDefaultSortOrder,
  getNonPalletSkus,
} from '../../../redux/selectors/rewrite/itemSkus'
import createAction from '../../../redux/actions/createAction'

import InputNoAutoComplete from '../../../common-components/semanticUiCustomComponents/InputNoAutoComplete'
import ReactDatePicker from '../../../common-components/rewrite/ReactDatepicker'
import ForeignKegExplanation from '../../../common-components/ForeignKegExplanation'
import FormAsTableWithMultipleApiSubmits from '../../../common-components/rewrite/formAsTable/FormAsTableWithMultipleApiSubmits'
import FormSectionAsTable from '../../../common-components/rewrite/formAsTable/FormSectionAsTable'
import ContainerTypesDifferences from '../../ContainerTypesDifferences'

import {
  getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm,
  getIsSubsidiaryATypeThatShouldDisplayShippedQtysInAckInboundShipmentsForm,
  getIsSubsidiaryATypeThatShouldTrackForeignKegs,
} from '../../../redux/selectors/rewrite/subsidiaries'

import {
  FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS,

  FIELD_NAME_ORDER_ID,
  FIELD_NAME_SHIPMENT_ID,
  FIELD_NAME_CARRIER,
  FIELD_NAME_DATE_SHIPPED,
  FIELD_NAME_SOURCE,
  FIELD_NAME_TOTAL_NUMBER_KEGS,

  FIELD_NAME_DATE_RECEIVED,
  FIELD_NAME_GOOD_PALLETS,
  FIELD_NAME_BAD_PALLETS,
  FIELD_NAME_FOREIGN_KEGS,

  FIELD_LABEL_ORDER_ID,
  FIELD_LABEL_SHIPMENT_ID,
  FIELD_LABEL_CARRIER,
  FIELD_LABEL_DATE_SHIPPED,
  FIELD_LABEL_SOURCE,
  FIELD_LABEL_DATE_RECEIVED,
  FIELD_LABEL_GOOD_PALLETS,
  FIELD_LABEL_BAD_PALLETS,
  FIELD_LABEL_GOOD_WOOD_PALLETS,
  FIELD_LABEL_BAD_WOOD_PALLETS,
  FIELD_LABEL_FOREIGN_KEGS,
  FIELD_LABEL_TOTAL_NUMBER_KEGS,
  FIELD_LABEL_SHIPMENT_TYPE,
  FIELD_NAME_SHIPMENT_TYPE,
  FIELD_TOUCHED,
  FIELD_ROW_INDEX,
  FIELD_LABEL_UNKNOWN_PLASTIC_PALLETS,
  FIELD_NAME_UNKNOWN_PALLETS,
} from '../../../constants/formAndApiUrlConfig/acknowledgeInboundShipments'

import {
  ITEM_SKU_IDS_CBI_PLASTIC_PALLET,
} from '../../../constants'

import {
  getShouldPalletsColumnsBeRendered,
  determineFieldsToOmit,
  getInitialValues,
  getDoesAckInboundShipmentsApiErrorSayShipmentHasAlreadyBeenAcked,
  getDateFieldFencingOfAcknowledgeInboundShipmentsRow,
  getIsFieldRowFullyFilledOut,
  getIsFieldRowPartiallyFilledOut,
  getUnacknowledgedShipmentSkus,
} from '../util'

import {
  FETCH_ACKNOWLEDGE_INBOUND_SHIPMENTS,
} from '../../../redux/actions/actionTypes'

import {
  sortArrayByTemplateArray,
  isTruthyAndNonEmpty,
  getHumanReadableContainerTypeFromItemSku,
} from '../../../utils'

import {
  normalizerCombiner,
  nonZeroPaddedPositiveIntegerOrZero,
  maxLength,
} from '../../../utils/formNormalization'

import {
  NestedPropNotInObjectError,
} from '../../../customErrors'
import TextAsPseudoLink from '../../../common-components/TextAsPseudoLink'
import { ACK_INBOUND_SHIPMENTS_UNFILLED_WARNING } from '../../../constants/labelCaptions'


const semanitcUiFormProps = {
  id: 'acknowledgeInboundShipmentsForm',
  className: 'form-top-spacing',
}


const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE'
const RESET_FORM_VALUES_TO_INITIAL_VALUES = 'RESET_FORM_VALUES_TO_INITIAL_VALUES'

/* eslint-disable no-param-reassign, no-useless-return */
const changeFormValue = produce((formValues, action) => {
  switch (action.type) {
    case CHANGE_FORM_VALUE: {
      // eslint-disable-next-line prefer-const
      const {
        rowIndex,
        fieldName,
        entireItemSkusSlice,
      } = action.payload
      let {
        value,
      } = action.payload
      const previousValue = formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex][fieldName]
      value = normalizeInputValue({
        previousValue,
        value,
        fieldName,
        entireItemSkusSlice,
      })
      formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex][fieldName] = value
      formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex][FIELD_TOUCHED] = true
      formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex][FIELD_ROW_INDEX] = rowIndex
      // Re-calculate and change the "Good Pallets" field if the user has
      // changed a keg qty field
      if (Object.keys(entireItemSkusSlice).includes(fieldName)) {
        // eslint-disable-next-line no-param-reassign
        formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex][FIELD_NAME_GOOD_PALLETS] =
          calculateGoodPalletsValue({
            rowValues: formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex],
            entireItemSkusSlice,
          })
      }

      // Re-calculate and change the "Total Number of Kegs" field if the user
      // has changed a keg qty or Foriegn Kegs field.
      if ([...Object.keys(entireItemSkusSlice), FIELD_NAME_FOREIGN_KEGS].includes(fieldName)) {
        // eslint-disable-next-line no-param-reassign
        formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex][FIELD_NAME_TOTAL_NUMBER_KEGS] =
          calculateTotalNumberOfKegsValue({
            rowValues: formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS][rowIndex],
            entireItemSkusSlice,
          })
      }
      return
    }
    case RESET_FORM_VALUES_TO_INITIAL_VALUES: {
      const {
        entireSubsidiariesSlice,
        customerId,
        subsidiaryId,
        inboundUnacknowledgedShipmentsSliceForThisCustomer,
      } = action.payload
      formValues = getInitialValues({
        entireSubsidiariesSlice,
        customerId,
        subsidiaryId,
        inboundUnacknowledgedShipmentsSliceForThisCustomer,
      })
      return
    }
    default: { return }
  }
})
/* eslint-enable no-param-reassign, no-useless-return */

const ContainerGroupByComponent = props => {
  const { children=[], itemSkuIds, dispatchFormValues, index, entireItemSkusSlice } = props
  // const shipmentId = formValues?.[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS]?.[index]?.[FIELD_NAME_SHIPMENT_ID]
  // check whether the item sku length !== ro the children length. note ignore date-received & null keys
  const showLink = itemSkuIds.length !== (Number(children?.filter(({ key }) => Boolean(key))?.length || 1) -1)
  const receivedDifferentLinkText = 'Received different keg?'
  return (
    <React.Fragment>
      <div style={{ paddingLeft: '1.5em' }} id='group-by-rows'>
        {props?.groupByLabel && <div className="required field toplabel"><label>{props?.groupByLabel}</label></div>}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {props?.children}
          {showLink && <TextAsPseudoLink
            onClick={() => {
              dispatchFormValues(createAction(
                CHANGE_FORM_VALUE,
                {
                  rowIndex: index,
                  fieldName: 'allowNonShipmentSkus',
                  value: true,
                  entireItemSkusSlice,
                },
              ))
            }}
          >
            {receivedDifferentLinkText}
          </TextAsPseudoLink>
        }
        </div>
      </div>
    </React.Fragment>
  )
}

const PalletGroupByComponent = props => (
  <div style={{ padding: '0 1.5em', marginLeft: '6em' }} id='group-by-rows'>
    <div style={{ display: 'flex', flexDirection: 'column' }}>{props.children}</div>
  </div>
)

export default props => {
  const {
    customerId,
    refetchInboundUnackedShipments,
    entireItemSkusSlice,
    entireSubsidiariesSlice,
    inboundUnacknowledgedShipmentsSliceForThisCustomer,
    businessUnitId,
    subsidiaryId,
    itemSkuIds: itemSkuIdsUnsorted,
    dispatch,
  } = props
  const { t: translate } = useTranslation()
  const itemSkuIds = useMemo(() => {
    const itemSkusForWebAppForms = getNonPalletSkus({
      entireItemSkusSlice,
      itemSkuIds: itemSkuIdsUnsorted,
    })
    return sortArrayByTemplateArray(
      itemSkusForWebAppForms,
      getItemSkusDefaultSortOrder({ entireItemSkusSlice }),
    )
  },
  [entireItemSkusSlice, itemSkuIdsUnsorted],
  )
  const shouldPalletsColumnsBeRendered = useMemo(
    () => getShouldPalletsColumnsBeRendered({
      entireSubsidiariesSlice,
      subsidiaryId,
    }),
    [entireSubsidiariesSlice, subsidiaryId],
  )
  const fieldsToOmit = useMemo(
    () => determineFieldsToOmit({ inboundUnacknowledgedShipmentsSliceForThisCustomer }),
    [inboundUnacknowledgedShipmentsSliceForThisCustomer],
  )
  const initialValues = useMemo(
    () => getInitialValues({
      entireSubsidiariesSlice,
      customerId,
      subsidiaryId,
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
    }),
    [
      entireSubsidiariesSlice,
      customerId,
      subsidiaryId,
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
    ],
  )

  const [formValues, dispatchFormValues] = useReducer(
    changeFormValue,
    getInitialValues({
      entireSubsidiariesSlice,
      customerId,
      subsidiaryId,
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
    }),
  )

  const fieldDefinitions = useMemo(
    () => createFieldArrayDefinitions({
      customerId,
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
      itemSkuIds,
      shouldPalletsColumnsBeRendered,
      entireItemSkusSlice,
      entireSubsidiariesSlice,
      dispatchFormValues,
      businessUnitId,
      subsidiaryId,
      translate,
    }),
    [
      customerId,
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
      itemSkuIds,
      entireItemSkusSlice,
      entireSubsidiariesSlice,
      shouldPalletsColumnsBeRendered,
      businessUnitId,
      subsidiaryId,
      translate,
    ],
  )

  const [isFormSubmittable, setIsFormSubmittable] = useState(false)
  useEffect(
    () => {
      setIsFormSubmittable(getIsFormSubmittable({
        inboundUnacknowledgedShipmentsSliceForThisCustomer,
        entireSubsidiariesSlice,
        subsidiaryId,
        formValues,
        itemSkuIds,
        entireItemSkusSlice,
      }))
    },
    [
      inboundUnacknowledgedShipmentsSliceForThisCustomer,
      entireSubsidiariesSlice,
      subsidiaryId,
      formValues,
      itemSkuIds,
      entireItemSkusSlice,
    ],
  )

  const [hasEntireFormBeenSubmitted, setHasEntireFormBeenSubmitted] = useState(false)
  const [areSomeRowsSubmitting, setAreSomeRowsSubmitting] = useState(false)
  const [didSomeRowSubmissionsFail, setDidSomeRowSubmissionsFail] = useState(false)
  const [didAllRowSubmissionsSucceed, setDidAllRowSubmissionsSucceed] = useState(false)
  const [fetchStatuses, dispatchFetchStatuses] = useReducer(fetchStatusesReducer, {})

  useEffect(
    () => {
      const [
        hasEntireFormBeenSubmitted_,
        areSomeRowsSubmitting_,
        didSomeRowSubmissionsFail_,
        didAllRowSubmissionsSucceed_,
      ] = getAggregateRowFetchStatuses(fetchStatuses)
      setHasEntireFormBeenSubmitted(hasEntireFormBeenSubmitted_)
      setAreSomeRowsSubmitting(areSomeRowsSubmitting_)
      setDidSomeRowSubmissionsFail(didSomeRowSubmissionsFail_)
      setDidAllRowSubmissionsSucceed(didAllRowSubmissionsSucceed_)
    },
    [fetchStatuses],
  )

  const handleSubmit = () => {
    setHasEntireFormBeenSubmitted(true)

    dispatch(createAction(
      FETCH_ACKNOWLEDGE_INBOUND_SHIPMENTS,
      {
        customerId,
        inboundUnacknowledgedShipmentsSliceForThisCustomer,
        businessUnitId,
        entireSubsidiariesSlice,
        subsidiaryId,
        fieldDefinitions,
        entireItemSkusSlice,
        itemSkuIds,
        formValues,
        dispatchFetchStatuses,
      },
    ))
  }

  // When the form successfully finishes and the user clicks "Return to Customer
  // Portal", we want the app to re-fetch the list of inbound unacked shipments,
  // that way if the user returns to the Ack Shipments page, the form doesn't
  // still contain the shipments that were just acked.
  useEffect(
    () => () => {
      if (hasEntireFormBeenSubmitted) {
        refetchInboundUnackedShipments()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasEntireFormBeenSubmitted],
  )

  return (
    <div>
      {/* In other forms, if a single required field is filled out, all */}
      {/* required fields of the row must be completely filled out before the */}
      {/* form can be submitted. This tends to be intuitive for the user, so */}
      {/* we don't add a note explaining the behavior in those forms. But this */}
      {/* form has less intuitive behavior: the Good Pallets and Bad Pallets */}
      {/* fields are auto-populated for every row, but if the user doesn't */}
      {/* fill out any other fields for a row, making it so that the Good */}
      {/* Pallets and Bad Pallets fields are the only fields filled out for */}
      {/* that row, the form will still be submittable but that row won't */}
      {/* be submitted. We add this note because users might be confused */}
      {/* about this behavior. */}
      {
        // Don't show the message if the form only has one row
        formValues &&
        formValues[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS].length > 1 && (
          <p style={{ fontStyle: 'italic', color: 'grey' }}>
            {`* ${ACK_INBOUND_SHIPMENTS_UNFILLED_WARNING}.`}
          </p>
        )
      }
      <FormAsTableWithMultipleApiSubmits
        semanitcUiFormProps={semanitcUiFormProps}
        isFormSubmittable={isFormSubmittable}
        handleSubmit={handleSubmit}
        hasEntireFormBeenSubmitted={hasEntireFormBeenSubmitted}
        areSomeRowsSubmitting={areSomeRowsSubmitting}
        didSomeRowSubmissionsFail={didSomeRowSubmissionsFail}
        didAllRowSubmissionsSucceed={didAllRowSubmissionsSucceed}
        onResetFormToInitialValues={refetchInboundUnackedShipments}
      >
        <FormSectionAsTable
          fieldDefinitions={fieldDefinitions}
          fetchStatuses={fetchStatuses}
          hasEntireFormBeenSubmitted={hasEntireFormBeenSubmitted}
          fieldArrayName={FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS}
          formValues={formValues}
          abilityToAddAndDeleteRows={false}
          initialValues={initialValues}
          fieldsToOmit={fieldsToOmit}
          dispatchFormValues={dispatchFormValues}
          entireItemSkusSlice={entireItemSkusSlice}
          itemSkuIds={itemSkuIds}
          showSubmitField
          customRenderFailurePopupInstructions={[
            // Shipment has already been acknowledged:
            // https://microstartap3.atlassian.net/browse/TP3-1728
            {
              when: getDoesAckInboundShipmentsApiErrorSayShipmentHasAlreadyBeenAcked,
              render: () => (
                // eslint-disable-next-line max-len
                <span>{`${translate('ackInboundShipments.This shipment has already been acknowledged--maybe a different employee in your company already acknowledged it? You can search for it in the Inbound Shipments history tab in the Customer Portal')}.`}</span>
              ),
            },
          ]}
        />
      </FormAsTableWithMultipleApiSubmits>
    </div>
  )
}


// Helper functions

function createFieldArrayDefinitions({
  customerId,
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  itemSkuIds,
  shouldPalletsColumnsBeRendered,
  entireItemSkusSlice,
  entireSubsidiariesSlice,
  dispatchFormValues,
  businessUnitId,
  subsidiaryId,
  translate,
}) {
  const itemSkuQtyFieldsDefinitions = itemSkuIds.map(itemSkuId => (
    {
      groupByFields: true,
      groupByLabel: `${translate('ackInboundShipments.Keg Types')}:`,
      groupByComponent: ContainerGroupByComponent,
      topLabel: getHumanReadableContainerTypeFromItemSku(itemSkuId, entireItemSkusSlice),
      fieldName: itemSkuId,
      showLabel: true,
      formFieldProps: {
        required: true,
        style: { width: '75px' },
      },
      fieldComponent: InputNoAutoComplete,
      renderOnlyIf: ({
        values,
      }) => {
        const shipmentId = values?.[FIELD_NAME_SHIPMENT_ID]
        const shipmentSkus = flow_(
          valuesFp_,
          filterFp_(o => o?.shipmentId === shipmentId),
          mapFp_(o => o),
          obj => getUnacknowledgedShipmentSkus(obj, entireItemSkusSlice),
        )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
        return values?.allowNonShipmentSkus || shipmentSkus?.includes(itemSkuId)
      },
      fieldProps: ({
        formValues,
        rowIndex,
      }) => {
        const value_ = get_(
          formValues,
          [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex, itemSkuId],
          '', // CODE_COMMENTS_245
        )
        return {
          placeholder: translate('Quantity'),
          value: value_,
          onChange: (event, { value }) => {
            dispatchFormValues(createAction(
              CHANGE_FORM_VALUE,
              { rowIndex, fieldName: itemSkuId, value, entireItemSkusSlice },
            ))
          },
        }
      },
      arbitraryComponentAfterLabel: () => (
        <ContainerTypesDifferences
          customerId={customerId}
          itemSkuId={itemSkuId}
        />
      ),
      arbitraryComponentBeforeFieldComponent: ({
        formValues,
        fieldArrayName,
        rowIndex,
      }) => {
        // https://microstartap3.atlassian.net/browse/TP3-5371
        if (getIsSubsidiaryATypeThatShouldDisplayShippedQtysInAckInboundShipmentsForm({
          entireSubsidiariesSlice,
          subsidiaryId,
        })) {
          const shipmentId = formValues[fieldArrayName][rowIndex][FIELD_NAME_SHIPMENT_ID]
          if (!shipmentId) { // first time the form is rendered
            return null
          }
          const shippedQtyForThisItemSku = flow_(
            findFp_(o => o.shipmentId === shipmentId),
            o => o.lineItems,
            findFp_(lineItem => lineItem?.itemSkuId === itemSkuId && lineItem?.linkType === 'SHIPPED'),
            lineItem => lineItem?.quantity,
          )(inboundUnacknowledgedShipmentsSliceForThisCustomer)
          if (!shippedQtyForThisItemSku) {
            return null
          }
          return (
            <span
              style={{
                marginRight: '1.5rem',
                marginLeft: '1rem',
                fontStyle: 'italic',
                color: '#595959',
              }}
            >{`${shippedQtyForThisItemSku} ${translate('expected')}`}</span>
          )
        }
        return null
      },
    }
  ))
  // Filter out pallet for specific BU
  const palletSkus = itemSkuIds.filter(itemSkuId => businessUnitId === entireItemSkusSlice?.[itemSkuId]?.businessUnit)
  const palletFieldsInfo = (palletSkus.length > 0 && [
    {
      // CODE_COMMENTS_277
      topLabel: palletSkus.includes(ITEM_SKU_IDS_CBI_PLASTIC_PALLET)
        ? translate(`${FIELD_LABEL_GOOD_WOOD_PALLETS}`)
        : translate(`${FIELD_LABEL_GOOD_PALLETS}`),
      fieldName: FIELD_NAME_GOOD_PALLETS,
    },
    {
      // CODE_COMMENTS_277
      topLabel: palletSkus.includes(ITEM_SKU_IDS_CBI_PLASTIC_PALLET)
        ? translate(`${FIELD_LABEL_BAD_WOOD_PALLETS}`)
        : translate(`${FIELD_LABEL_BAD_PALLETS}`),
      fieldName: FIELD_NAME_BAD_PALLETS,
    },
  ]) || []

  const palletsFieldsDefinitions = shouldPalletsColumnsBeRendered
    ? palletFieldsInfo.map(palletFieldInfo => ({
      topLabel: palletFieldInfo.topLabel,
      fieldName: palletFieldInfo.fieldName,
      showLabel: true,
      groupByFields: true,
      groupByComponent: PalletGroupByComponent,
      formFieldProps: {
        required: true,
      },
      fieldComponent: InputNoAutoComplete,
      fieldProps: ({
        formValues,
        rowIndex,
      }) => {
        const value_ = get_(
          formValues,
          [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex, palletFieldInfo.fieldName],
          '', // CODE_COMMENTS_245
        )
        return {
          placeholder: translate('Quantity'),
          value: value_,
          onChange: (event, { value }) => {
            dispatchFormValues(createAction(
              CHANGE_FORM_VALUE,
              { rowIndex, fieldName: palletFieldInfo.fieldName, value, entireItemSkusSlice },
            ))
          },
        }
      },
    }))
    : []
    const trackUnknownPlasticKegs = subsidiaryId === 36
    const UnknownPalletsFieldsDefinition = trackUnknownPlasticKegs ? [{
        topLabel: translate(`${FIELD_LABEL_UNKNOWN_PLASTIC_PALLETS}`),
        fieldName: FIELD_NAME_UNKNOWN_PALLETS,
        showLabel: true,
        groupByFields: true,
        groupByComponent: PalletGroupByComponent,
        formFieldProps: {
            required: true,
        },
        fieldComponent: InputNoAutoComplete,
        fieldProps: ({
                         formValues,
                         rowIndex,
                     }) => {
            const value_ = get_(
                formValues,
                [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex, FIELD_NAME_UNKNOWN_PALLETS],
                '', // CODE_COMMENTS_245
            )
            return {
                placeholder: translate('Quantity'),
                value: value_,
                onChange: (event, { value }) => {
                    dispatchFormValues(createAction(
                        CHANGE_FORM_VALUE,
                        { rowIndex, fieldName: FIELD_NAME_UNKNOWN_PALLETS, value, entireItemSkusSlice },
                    ))
                },
            }
        },
    }] : []

  const trackForeignKegs = getIsSubsidiaryATypeThatShouldTrackForeignKegs({
    entireSubsidiariesSlice,
    subsidiaryId,
  })
  const foreignKegsFieldsDefinition = trackForeignKegs ? [{
    topLabel: translate(`${FIELD_LABEL_FOREIGN_KEGS}`),
    fieldName: FIELD_NAME_FOREIGN_KEGS,
    showLabel: true,
    groupByFields: true,
    groupByComponent: PalletGroupByComponent,
    formFieldProps: {
      required: true,
    },
    fieldComponent: InputNoAutoComplete,
    fieldProps: ({
      formValues,
      rowIndex,
    }) => {
      const value_ = get_(
        formValues,
        [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex, FIELD_NAME_FOREIGN_KEGS],
        '', // CODE_COMMENTS_245
      )
      return {
        placeholder: translate('Quantity'),
        value: value_,
        onChange: (event, { value }) => {
          dispatchFormValues(createAction(
            CHANGE_FORM_VALUE,
            { rowIndex, fieldName: FIELD_NAME_FOREIGN_KEGS, value, entireItemSkusSlice },
          ))
        },
      }
    },
    informationalIconContent: <ForeignKegExplanation />,
  }] : []

  return [
    ...(getIsSubsidiaryATypeThatShouldDisplayOrderIdInAckInboundShipmentsForm({
      entireSubsidiariesSlice,
      subsidiaryId,
    })
      ? [
        {
          showLabel: true,
          topLabel: translate(`${FIELD_LABEL_ORDER_ID}`),
          fieldName: FIELD_NAME_ORDER_ID,
          formFieldProps: { style: { width: '175px' } },
          noFieldJustText: true,
        },
      ]
      : []
    ),
    {
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_SHIPMENT_ID}`),
      fieldName: FIELD_NAME_SHIPMENT_ID,
      formFieldProps: { style: { width: '175px' } },
      noFieldJustText: true,
    },
    {
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_SHIPMENT_TYPE}`),
      fieldName: FIELD_NAME_SHIPMENT_TYPE,
      formFieldProps: { style: { width: '175px' } },
      noFieldJustText: true,
    },
    {
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_DATE_SHIPPED}`),
      fieldName: FIELD_NAME_DATE_SHIPPED,
      formFieldProps: { style: { width: '100px' } },
      noFieldJustText: true,
    },

    {
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_CARRIER}`),
      fieldName: FIELD_NAME_CARRIER,
      formFieldProps: { style: { width: '200px' } },
      noFieldJustText: true,
    },


    {
      // This label isn't 'Shipper' because 'shipper' can too easily be confused
      // with 'carrier'
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_SOURCE}`),
      fieldName: FIELD_NAME_SOURCE,
      formFieldProps: { style: { width: '250px' } },
      noFieldJustText: true,
    },

    {
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_TOTAL_NUMBER_KEGS}`),
      fieldName: FIELD_NAME_TOTAL_NUMBER_KEGS,
      formFieldProps: { style: { width: '150px' } },
      noFieldJustText: true,
    },
    {
      groupByFields: true,
      groupByComponent: ContainerGroupByComponent,
      showLabel: true,
      topLabel: translate(`${FIELD_LABEL_DATE_RECEIVED}`),
      fieldName: FIELD_NAME_DATE_RECEIVED,
      formFieldProps: { required: true, style: { width: '200px' } },
      fieldComponent: ReactDatePicker,
      fieldProps: ({
        formValues,
        rowIndex,
      }) => ({
        ...getDateFieldFencingOfAcknowledgeInboundShipmentsRow({
          inboundUnacknowledgedShipmentsSliceForThisCustomer,
          rowIndex,
        }),
        compact: true,
        value: get_(
          formValues,
          [FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS, rowIndex, FIELD_NAME_DATE_RECEIVED],
          '', // CODE_COMMENTS_245
        ),
        onChange: value => {
          dispatchFormValues(createAction(
            CHANGE_FORM_VALUE,
            { rowIndex, fieldName: FIELD_NAME_DATE_RECEIVED, value, entireItemSkusSlice },
          ))
        },
      }),
    },
    ...itemSkuQtyFieldsDefinitions,
    ...palletsFieldsDefinitions,
    ...foreignKegsFieldsDefinition,
    ...UnknownPalletsFieldsDefinition,
  ]
}


function getIsFormSubmittable({
  inboundUnacknowledgedShipmentsSliceForThisCustomer,
  entireSubsidiariesSlice,
  subsidiaryId,
  formValues,
  entireItemSkusSlice,
}) {
  if (!formValues) { return false } // form hasn't yet been rendered
  const touchedFormValues = formValues?.[FIELD_ARRAY_NAME_ACK_INBOUND_SHIPMENTS]?.filter(obj => obj[FIELD_TOUCHED])
  if (!touchedFormValues || touchedFormValues?.length === 0) { return false } // check only touched formValues
  try {
    if (
      // ensure valid delivery dates have been chosen
      // !isTruthyAndNonEmpty(getFormSyncErrors(formName)(state))
      // &&
      // ensure at least one row has been fully filled out
      touchedFormValues.some(
        rowValues => getIsFieldRowFullyFilledOut({
          inboundUnacknowledgedShipmentsSliceForThisCustomer,
          entireSubsidiariesSlice,
          subsidiaryId,
          itemSkuIds: flow_(
            valuesFp_,
            filterFp_(o => o?.shipmentId === rowValues[FIELD_NAME_SHIPMENT_ID]),
            mapFp_(o => o),
            obj => getUnacknowledgedShipmentSkus(obj, entireItemSkusSlice),
          )(inboundUnacknowledgedShipmentsSliceForThisCustomer),
          rowValues,
          rowIndex: rowValues?.[FIELD_ROW_INDEX], // don't send array index
        }))
      &&
      // ensure no rows have been partially filled out (it's OK if the user has
      // created a row but hasn't entered any information into it)
      !touchedFormValues.some(
        rowValues => getIsFieldRowPartiallyFilledOut({
          inboundUnacknowledgedShipmentsSliceForThisCustomer,
          entireSubsidiariesSlice,
          subsidiaryId,
          itemSkuIds: flow_(
            valuesFp_,
            filterFp_(o => o?.shipmentId === rowValues[FIELD_NAME_SHIPMENT_ID]),
            mapFp_(o => o),
            obj => getUnacknowledgedShipmentSkus(obj, entireItemSkusSlice),
          )(inboundUnacknowledgedShipmentsSliceForThisCustomer),
          rowValues,
          rowIndex: rowValues?.[FIELD_ROW_INDEX], // don't send array index
        }))
      /* eslint-disable indent */
      // A note about the Pallets fields: They must be filled out with some
      // quantity, even if 0. These fields get pre-populated (see the
      // createPerCellFieldProps function for details), but the user can change
      // the values to whatever they want, even all zeroes. Why is all zeroes
      // valid? It's true that all zeroes is technically incorrect data (kegs
      // _always_ get delivered with at least 1 pallet), but in TAP2, the user
      // is allowed to enter all zeroes, and they sometimes do. When they do,
      // the Logistics team manually changes these values to all good pallets,
      // no bad pallets. Brett and Derek from the Logistics team said on 4/27/18
      // that they actually like this TAP2 feature and they want it to stay the
      // same in TAP3.
      /* eslint-enable indent */

    ) { return true }
  // Why are we wrapping basically the entire getIsFormSubmittable() function in
  // a try...catch block? Because when the user successfully submits the form
  // then clicks "Fill out Form Again" button, the web app will re-fetch all
  // inbound unacknowledged shipments (with a loading spinner displayed while
  // it's doing this), and then, when this form is re-rendered for the user to
  // fill out again, the getIsFieldRowFullyFilledOut() and
  // getIsFieldRowPartiallyFilledOut() functions will encounter a
  // NestedPropNotInObjectError. Why? Because just before re-fetching inbound
  // unacknowledged shipments, the web app resets the form. This resets the form
  // to its initial values _as of successful submission_: let's say you had 2
  // rows when you submitted, only submitting one of them, then when you click
  // on "Fill out Form Again", you expect the form to have only one row when
  // inbound unacknowledged shipments are finished re-fetching. This is a
  // reasonable assumption. However, the "initialValues" of the form after it
  // was successfully submitted has _two_ rows in it, i.e. information on the
  // row you just submitted and the row you expect to see on re-render. But the
  // row you just submited gets deleted from the
  // `inboundUnacknowledgedShipments` slice of the Redux store once the inbound
  // unacknowledged shipments get re-fetched. That row, however, is still stuck
  // in the 'initialValues' of this form, and the very first time the app
  // renders this form after inbound unacknowledged shipments get refetched,
  // this function is going to iterate over that now-gone-from-redux row. The
  // form needs to fully re-render once before the initialValues get reset so
  // that it no longer contains the now-gone-from-redux shipment. Catching the
  // error and returning false in this function allows React to fully
  // re-render the form, thereby resetting initialValues so that it no longer
  // contains the now-acknowledged, now-gone-from-redux shipment.
  } catch (e) {
    if (e instanceof NestedPropNotInObjectError) {
      return false
    }
    throw e
  }

  return false
}

// {
//   'HB-MS-D-U': 17,
//   'SB-MS-D-U': 4,
//   'QB-MS-D-U': 86,
// }
// -> 11
function calculateGoodPalletsValue({
  rowValues,
  entireItemSkusSlice,
}) {
  // {
  //   'HB-MS-D-U': 17,
  //   'SB-MS-D-U': 4,
  //   'QB-MS-D-U': 86,
  // }
  const kegValues = pick_(rowValues, Object.keys(entireItemSkusSlice))
  // user has deleted all keg values
  if (!isTruthyAndNonEmpty(kegValues)) { return undefined }
  const toReturn = Object.keys(kegValues).reduce(
    (acc, itemSkuId) => (
      acc + caclulateNumberOfPalletsNeededForXKegs(
        kegValues[itemSkuId],
        itemSkuId === ITEM_SKU_IDS_CBI_PLASTIC_PALLET
          // One Constellation Plastic Pallet (CPP) counts as negative 1 good
          // wood pallet.
          ? -1
          : entireItemSkusSlice[itemSkuId].itemsPerPallet,
      )
    ),
    0,
  )
  // Because 1 Constellation Plastic Pallet (CPP) counts as negative 1 good wood
  // pallet, toReturn can fall below 0, which we want to prevent.
  return Math.max(toReturn, 0)
}


function calculateTotalNumberOfKegsValue({
  rowValues,
  entireItemSkusSlice,
}) {
  return flow_(
    pickFp_([...Object.keys(entireItemSkusSlice), FIELD_NAME_FOREIGN_KEGS]),
    omitFp_([ITEM_SKU_IDS_CBI_PLASTIC_PALLET]), // CODE_COMMENTS_277
    valuesFp_,
    mapFp_(value => (value ? Number(value) : 0)),
    sumFp_,
  )(rowValues)
}


function caclulateNumberOfPalletsNeededForXKegs(numberOfKegs, numberOfKegsThatFitOnPallet) {
  const numberOfKegsThatFitOnPalletActualValue = (
    numberOfKegsThatFitOnPallet
    // If somehow a web app customer is configured with an itemSku whose
    // itemsPerPallet prop is null, set a value of 0
    || 0
  )
  const numberOfKegsNumber = Number(numberOfKegs)
  const numberOfKegsThatFitOnPalletNumber = Number(numberOfKegsThatFitOnPalletActualValue)

  return numberOfKegsNumber%numberOfKegsThatFitOnPalletNumber
    ? Math.floor(numberOfKegsNumber/numberOfKegsThatFitOnPalletNumber)+1
    : Math.floor(numberOfKegsNumber/numberOfKegsThatFitOnPalletNumber)
}


function normalizeInputValue({
  previousValue,
  value,
  fieldName,
  entireItemSkusSlice,
}) {
  let normalizer
  if ([
    ...Object.keys(entireItemSkusSlice),
    FIELD_NAME_GOOD_PALLETS,
    FIELD_NAME_BAD_PALLETS,
    FIELD_NAME_FOREIGN_KEGS,
  ].includes(fieldName)) {
    normalizer = normalizerCombiner(nonZeroPaddedPositiveIntegerOrZero, maxLength(5))
  } else {
    normalizer = val => val
  }
  return normalizer(
    value,
    previousValue,
  )
}
