// These sagas are meant to fetch specific entity types. But haven't we already
// written such sagas within the /redux/sagas/customers folder (a couple levels
// up)? Yes, and those are the sagas that these eventually end up calling, but
// these sagas are smarter than those: these check specific info of the customer
// passed in and only call the saga if necessary. For example, the
// fetchChildrenOfCustomer saga first checks to see whether a customer is a type
// that has children, and only then performs the fetch. These functions also
// make sure the user has the required permissions (via its `permissions` and
// `perCustomerPermissions` ) to make the fetch. We put this kind of conditional
// logic here rather in the callers in order to simplify things on the caller's
// end. This is super important because those callers are themselves recursive
// functions (e.g. get customer info of this customer and all the customer's
// children), so they already have plenty of complexity introduced by recursion.


/* eslint-disable no-restricted-syntax */ // "for of" loops OK in this file, see CODE COMMENTS 50


import { call, put, select } from 'redux-saga/effects'

import isPlainObj_ from 'lodash/isPlainObject'


import {
  FETCH_COLLAR_PLATES_INFO,
  FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS,
  FETCH_CONTACTS,
  FETCH_CUSTOMER_BALANCES,
  FETCH_STORED_BANK_ACCOUNT_INFO,
  FETCH_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
  FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY, FETCH_CUSTOMER_MESSAGES,
} from '../../../../actions/actionTypes'
import createAction from '../../../../actions/createAction'

import {
  getProp as getCustomerProp,
  getIsCustomerOfATypeThatHasChildren,
  getIsCustomerOfATypeThatHasAddresses,
  getIsCustomerOfATypeThatHasContractsCalledOnIt,
  getIsCustomerOfATypeThatIsRelatedFromOtherCustomers,
  getIsCustomerOfATypeThatIsRelatedToOtherCustomers,
  getIsCustomerOfATypeThatHasInboundUnacknowledgedShipments,
  getIsCustomerOfATypeThatHasContacts,
  getIsCustomerOfATypeThatOrdersCollars,
  getIsCustomerOfATypeThatPaysBalances,
  getIsCustomerAWhType,
} from '../../../../selectors/customers'
import {
  getShouldReportOutboundShipmentsFormBeRendered,
  getShouldReportKegFillsFormBeRendered,
  getShouldReportBuybackShipmentsFormBeRendered,
} from '../../../../selectors/rewrite/forms/reportShipmentsCommon/shouldReportShipmentsFormBeRendered'
import {
  getEntireSlice as getEntireCustomersSlice,
} from '../../../../selectors/rewrite/customers'
import {
  getEntireSlice as getEntireContractsSlice,
  getContracts, getHasContractThatCanHaveKegsOrderedOnIt,
} from '../../../../selectors/rewrite/contracts'
import {
  getEntireSlice as getEntireRelationshipsSlice,
} from '../../../../selectors/rewrite/relationships/relatedToOrFromInfo'
import {
  getEntireSlice as getEntirePermissionsSlice,
} from '../../../../selectors/rewrite/permissions'
import {
  getEntireSlice as getEntireCurrentUserSlice,
} from '../../../../selectors/rewrite/currentUser'
import {
  getHasPermissionsToPerformFunctionality,
} from '../../../../selectors/permissions'
import {
  getShouldReportedInventoryVsCalculatedInventoryPageBeRendered,
} from '../../../../selectors/reportedInventoryVsCalculatedInventory'

import { fetchCustomerChildren } from '../../children'
import { fetchCustomerAddresses } from '../../addresses'
import { fetchContracts } from '../../contracts'
import { fetchCustomerRelatedToInfo } from '../../relatedToInfo'
import { fetchCustomerRelatedFromInfo } from '../../relatedFromInfo'
import {
  fetchCustomerInboundUnacknowledgedShipments as fetchInboundUnacknowledgedShipments,
} from '../../inboundUnacknowledgedShipments'

import childrenFetchStatusesSelectors from '../../../../selectors/fetchStatuses/customers/children'
import addressesFetchStatusesSelectors from '../../../../selectors/fetchStatuses/customers/addresses'
import contractsFetchStatusesSelectors from '../../../../selectors/fetchStatuses/customers/contracts'
import relatedToInfoFetchStatusesSelectors from '../../../../selectors/fetchStatuses/customers/relatedToInfo'
import relatedFromInfoFetchStatusesSelectors from '../../../../selectors/fetchStatuses/customers/relatedFromInfo'

import {
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,

  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS,
  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS,

  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS,
  FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS,

  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,

  FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,

  CUSTOMER_PARENTS,
  ADDRESSES,
  RELATIONSHIPS,
  READ,
} from '../../../../../constants/permissions'

import {
  CUSTOMER_TYPES_CONTRACT_BREWER,
  CUSTOMER_TYPES_BREWER,
  PPF_CONTRACT_TYPES_BRW,
  PPF_CONTRACT_TYPES_CBMST,
} from '../../../../../constants'

import {
  createCustIdAndOptionalConbrwCustIdReduxIdentifier,
  isTruthyAndNonEmpty,
} from '../../../../../utils'

import { PrelimInfoFetchNeededForRenderingDashboardFailedError } from '../../../../../customErrors'
import { fetchCustomerHolidayDays } from '../../../forms/orderKegs/orderKegs'


const { getFetchStatuses: getChildrenFetchStatuses } = childrenFetchStatusesSelectors
const { getFetchStatuses: getAddressesFetchStatuses } = addressesFetchStatusesSelectors
const { getFetchStatuses: getContractsFetchStatuses } = contractsFetchStatusesSelectors
const { getFetchStatuses: getRelatedToInfoFetchStatuses } = relatedToInfoFetchStatusesSelectors
const { getFetchStatuses: getRelatedFromInfoFetchStatuses } = relatedFromInfoFetchStatusesSelectors


export function* fetchChildrenOfCustomer({
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
  preventsDashboardFromLoadingIfFails,
}) {
  const isCustomerOfATypeThatHasChildren = yield select(
    getIsCustomerOfATypeThatHasChildren,
    customerId,
  )

  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: { [CUSTOMER_PARENTS]: [READ] },
    },
  )

  if (isCustomerOfATypeThatHasChildren && hasPermissions) {
    yield call(
      fetchCustomerChildren,
      {
        payload: {
          customerId,
          preventsDashboardFromLoadingIfFails,
        },
      })
    const childrenFetchStatuses = yield select(getChildrenFetchStatuses, customerId)
    if (
      childrenFetchStatuses.didFetchFail &&
      preventsDashboardFromLoadingIfFails
    ) {
      throw new PrelimInfoFetchNeededForRenderingDashboardFailedError()
    }
  }
}


export function* fetchAddressesOfCustomer({
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
  preventsDashboardFromLoadingIfFails,
}) {
  const isCustomerOfATypeThatHasAddresses = yield select(
    getIsCustomerOfATypeThatHasAddresses,
    customerId,
  )
  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: { [ADDRESSES]: [READ] },
    },
  )

  if (isCustomerOfATypeThatHasAddresses && hasPermissions) {
    yield call(
      fetchCustomerAddresses,
      {
        payload: {
          customerId,
          preventsDashboardFromLoadingIfFails,
        },
      },
    )
    const addressesFetchStatuses = yield select(getAddressesFetchStatuses, customerId)
    if (
      addressesFetchStatuses.didFetchFail &&
      preventsDashboardFromLoadingIfFails
    ) {
      throw new PrelimInfoFetchNeededForRenderingDashboardFailedError()
    }
  }
}

// CODE_COMMENTS_133, CODE_COMMENTS_202
export function* fetchContractsOfCustomer({
  customerId,
  preventsDashboardFromLoadingIfFails,
}) {
  if (yield select(getIsCustomerOfATypeThatHasContractsCalledOnIt, customerId)) {
    yield call(
      fetchContracts,
      {
        payload: {
          customerId,
          preventsDashboardFromLoadingIfFails,
        },
      },
    )
    const contractsFetchStatuses = yield select(getContractsFetchStatuses, customerId)
    if (
      contractsFetchStatuses.didFetchFail &&
      preventsDashboardFromLoadingIfFails
    ) {
      throw new PrelimInfoFetchNeededForRenderingDashboardFailedError()
    }
  }
}


export function* fetchRelatedToInfoOfCustomer({
  customerId,
  // CODE_COMMENTS_88. Pass in operatingContractBrewerCustomerId only if this is
  // a contractee brewer being operated on by a CB.
  operatingContractBrewerCustomerId,
  preventsDashboardFromLoadingIfFails,
}) {
  const isCustomerOfATypeThatIsRelatedToOtherCustomers = yield select(
    getIsCustomerOfATypeThatIsRelatedToOtherCustomers,
    customerId,
  )

  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: { [RELATIONSHIPS]: [READ] },
      customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId ? customerId : undefined,
    },
  )

  if (isCustomerOfATypeThatIsRelatedToOtherCustomers && hasPermissions) {
    yield call(
      fetchCustomerRelatedToInfo,
      {
        payload:
        {
          customerId,
          operatingContractBrewerCustomerId,
          preventsDashboardFromLoadingIfFails,
        },
      },
    )
    const relatedToInfoFetchStatuses = yield select(
      getRelatedToInfoFetchStatuses,
      // CODE_COMMENTS_196
      createCustIdAndOptionalConbrwCustIdReduxIdentifier(customerId, operatingContractBrewerCustomerId),
    )
    if (
      relatedToInfoFetchStatuses.didFetchFail &&
      preventsDashboardFromLoadingIfFails
    ) {
      throw new PrelimInfoFetchNeededForRenderingDashboardFailedError()
    }
  }
}


export function* fetchRelatedFromInfoOfCustomer({
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
  preventsDashboardFromLoadingIfFails,
}) {
  const isCustomerOfATypeThatIsRelatedFromOtherCustomers = yield select(
    getIsCustomerOfATypeThatIsRelatedFromOtherCustomers,
    customerId,
  )

  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: { [RELATIONSHIPS]: [READ] },
    },
  )

  if (isCustomerOfATypeThatIsRelatedFromOtherCustomers && hasPermissions) {
    yield call(
      fetchCustomerRelatedFromInfo,
      {
        payload: {
          customerId,
          preventsDashboardFromLoadingIfFails,
        },
      },
    )
    const relatedFromInfoFetchStatuses = yield select(getRelatedFromInfoFetchStatuses, customerId)
    if (
      relatedFromInfoFetchStatuses.didFetchFail &&
      preventsDashboardFromLoadingIfFails
    ) {
      throw new PrelimInfoFetchNeededForRenderingDashboardFailedError()
    }
  }
}


export function* fetchInboundUnacknowledgedShipmentsOfCustomer(
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
  const isCustomerOfATypeThatHasInboundUnacknowledgedShipments = yield select(
    getIsCustomerOfATypeThatHasInboundUnacknowledgedShipments,
    customerId,
  )

  const state = yield select()
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const isWhCustomerType = getIsCustomerAWhType(state, customerId)

  const hasNonExpiredNonConbrwPpfContract = isTruthyAndNonEmpty(getContracts({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
    ppfContractTypes: [PPF_CONTRACT_TYPES_BRW, PPF_CONTRACT_TYPES_CBMST],
    notExpiredOnly: true,
    activeStatusOnly: true,
    noFutureContracts: true,
  }))

  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ACKNOWLEDGE_INBOUND_SHIPMENTS,
    },
  )

  if (
    (isCustomerOfATypeThatHasInboundUnacknowledgedShipments &&
    hasNonExpiredNonConbrwPpfContract &&
    hasPermissions) || isWhCustomerType // Exclude PPF contract for WH customer type
  ) {
    // We specifically DON'T want to do `yield
    // put(createAction(FETCH_INBOUND_UNACKNOWLEDGED_KEG_SHIPMENTS))` here,
    // because that would be a non-blocking call, and we want this call to block
    // until the fetching is finished, otherwise the dashboard will load before
    // we've determined whether the user has any unacked inbound shipments. We
    // want to make sure that if the user has unacked inbound shipments, we
    // render the "You have unacknowledged shipments!" modal as soon as the
    // dashboard is rendered, not several seconds after (or however long it
    // takes for the unacked inbound shipments call to finish).
    yield call(
      fetchInboundUnacknowledgedShipments,
      {
        payload: {
          customerId,
        },
      },
    )
  }
}


export function* fetchCollarPlatesOfCustomer(
  customerId,
  // CODE_COMMENTS_88. Pass in operatingContractBrewerCustomerId only if this is
  // a contractee brewer being operated on by a CB.
  operatingContractBrewerCustomerId,
) {
  if (yield select(getIsCustomerOfATypeThatOrdersCollars, customerId)) {
    yield call(
      fetchCollarPlatesOfCustomerNoCustomerTypeCheck,
      customerId,
      operatingContractBrewerCustomerId,
    )
  }
}


export function* fetchCollarPlatesOfCustomerNoCustomerTypeCheck(
  customerId,
  // CODE_COMMENTS_88. Pass in operatingContractBrewerCustomerId only if this is
  // a contractee brewer being operated on by a CB.
  operatingContractBrewerCustomerId,
) {
  const onlyFetchIfUserHasOneOrMoreOfThesePermissions = operatingContractBrewerCustomerId
    ? [
      FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_ORDER_COLLARS,
      FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
      FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_EDIT_COLLAR_ORDERS,
      FUNCTIONALITY_THAT_NEEDS_PER_CUSTOMER_PERMISSIONS_DELETE_COLLAR_ORDERS,
    ]
    : [
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_ORDER_COLLARS,
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_COLLAR_ORDER_HISTORY_TABLE,
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_EDIT_COLLAR_ORDERS,
      FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_DELETE_COLLAR_ORDERS,
    ]

  let hasPermissions = false
  // Why for of instead of forEach? CODE_COMMENTS_50
  for (const functionalityString of onlyFetchIfUserHasOneOrMoreOfThesePermissions) {
    const state = yield select()
    const hasPermissionsToPerformThisFunctionality = yield call(
      getHasPermissionsToPerformFunctionality,
      {
        state,
        functionalityStringOrPermissionsMap: functionalityString,
        customerIdIfThisIsAPerCustomerPermissionsCheck: operatingContractBrewerCustomerId ? customerId : undefined,
      },
    )
    if (hasPermissionsToPerformThisFunctionality) {
      hasPermissions = true
      break
    }
  }

  if (hasPermissions) {
    yield put(createAction(FETCH_COLLAR_PLATES_INFO, { customerId, operatingContractBrewerCustomerId }))
  }
}


// For the Manage Users form
export function* fetchCustomerUsersAndTheirPermissions(
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
    },
  )

  if (hasPermissions) {
    yield put(createAction(FETCH_CUSTOMER_USERS_AND_THEIR_PERMISSIONS, { customerId }))
  }
}


export function* fetchContacts(
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
  const state = yield select()
  const isCustomerOfATypeThatHasContacts = yield select(
    getIsCustomerOfATypeThatHasContacts,
    customerId,
  )
  const isWhCustomerType = getIsCustomerAWhType(state, customerId)
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_MANAGE_USERS_AND_CONTACTS,
    },
  )

  if ((isCustomerOfATypeThatHasContacts || isWhCustomerType) && hasPermissions) {
    yield put(createAction(FETCH_CONTACTS, { customerId }))
  }
}


export function* fetchCustomerBalances(
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
  const isCustomerOfATypeThatPaysBalances = yield select(
    getIsCustomerOfATypeThatPaysBalances,
    customerId,
  )

  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
    },
  )

  if (isCustomerOfATypeThatPaysBalances && hasPermissions) {
    yield put(createAction(FETCH_CUSTOMER_BALANCES, { customerId }))
  }
}

export function* fetchCustomerMessages(
    customerId,
    // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
    yield put(createAction(FETCH_CUSTOMER_MESSAGES, { customerId }))
}


export function* fetchBankAccountInfo(
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
  const isCustomerOfATypeThatPaysBalances = yield select(
    getIsCustomerOfATypeThatPaysBalances,
    customerId,
  )

  const state = yield select()
  const hasPermissions = yield call(
    getHasPermissionsToPerformFunctionality,
    {
      state,
      functionalityStringOrPermissionsMap: FUNCTIONALITY_THAT_NEEDS_PERMISSIONS_VIEW_AND_PAY_INVOICES,
    },
  )

  if (isCustomerOfATypeThatPaysBalances && hasPermissions) {
    yield put(createAction(FETCH_STORED_BANK_ACCOUNT_INFO, { customerId }))
  }
}


export function* fetchPotentialDuplicatesForReportShipmentsForms(
  customerId,
  // CODE_COMMENTS_88. Pass in operatingContractBrewerCustomerId only if this is
  // a contractee brewer being operated on by a CB.
  operatingContractBrewerCustomerId,
) {
  let shouldPotentialDuplicatesBeFetchedForThisCustomer = false
  const state = yield select()

  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const entirePermissionsSlice = getEntirePermissionsSlice(state)
  const entireCurrentUserSlice = getEntireCurrentUserSlice(state)

  const customerType = yield select(getCustomerProp, customerId, 'customerType')
  if (customerType === CUSTOMER_TYPES_BREWER) {
    const shouldReportOutboundShipmentsFormBeRendered = getShouldReportOutboundShipmentsFormBeRendered({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      customerId,
      operatingContractBrewerCustomerId,
    })
    // CODE_COMMENTS_231
    const {
      shouldOptionBeDisabled: shouldReportOutboundShipmentsFormOptionBeDisabled,
    } = isPlainObj_(shouldReportOutboundShipmentsFormBeRendered)
      ? shouldReportOutboundShipmentsFormBeRendered
      : {}
    const shouldReportBuybackShipmentsFormBeRendered = getShouldReportBuybackShipmentsFormBeRendered({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      customerId,
      operatingContractBrewerCustomerId,
    })
    if (
      (shouldReportOutboundShipmentsFormBeRendered && !shouldReportOutboundShipmentsFormOptionBeDisabled)
      || shouldReportBuybackShipmentsFormBeRendered
    ) {
      shouldPotentialDuplicatesBeFetchedForThisCustomer = true
    }
  } else if (customerType === CUSTOMER_TYPES_CONTRACT_BREWER) {
    if (getShouldReportKegFillsFormBeRendered({
      entireCustomersSlice,
      entireContractsSlice,
      entireRelationshipsSlice,
      entirePermissionsSlice,
      entireCurrentUserSlice,
      customerId,
    })) {
      shouldPotentialDuplicatesBeFetchedForThisCustomer = true
    }
  }

  if (shouldPotentialDuplicatesBeFetchedForThisCustomer) {
    yield put(createAction(
      FETCH_REPORT_SHIPMENTS_FORMS_POTENTIAL_DUPLICATES,
      { customerId, operatingContractBrewerCustomerId },
    ))
  }
}


export function* fetchReportedInventoryVsCalculatedInventoryOfCustomer(
  customerId,
  operatingContractBrewerCustomerId,
) {
  const state = yield select()
  const shouldReportedInventoryVsCalculatedInventoryPageBeRendered = (
    getShouldReportedInventoryVsCalculatedInventoryPageBeRendered({
      state,
      customerId,
      operatingContractBrewerCustomerId,
    })
  )

  if (shouldReportedInventoryVsCalculatedInventoryPageBeRendered) {
    yield put(createAction(
      FETCH_REPORTED_INVENTORY_VS_CALCULATED_INVENTORY,
      { customerId },
    ))
  }
}

// For the Manage Users form
export function* fetchOrderKegsUserPermission(
  customerId,
  // No operatingContractBrewerCustomerId? See CODE_COMMENTS_162
) {
  const state = yield select()
  const entireCustomersSlice = getEntireCustomersSlice(state)
  const entireContractsSlice = getEntireContractsSlice(state)
  const entireRelationshipsSlice = getEntireRelationshipsSlice(state)
  const hasPermissions = getHasContractThatCanHaveKegsOrderedOnIt({
    entireCustomersSlice,
    entireContractsSlice,
    entireRelationshipsSlice,
    customerId,
  })

  if (hasPermissions) {
    yield call(fetchCustomerHolidayDays, { payload: { customerId } })
  }
}
